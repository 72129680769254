import React, { useEffect, useState } from "react";
import axios from "axios";
import "./QuickLook.scss";
import { Card, Layout, Tooltip } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Helmet } from "react-helmet";
import MetaTagContents from "../../MetaTagContents";
import NavBar from "../landingPage/header/navbar";
import Footer from "../landingPage/footer";
import { PRODUCT_LIST, getBaseURL } from "../../env";

const productsList = [
  {
    category: "Kitchen",
    productIds: [4983, 19710, 4975],
    productsData: [],
  },
  {
    category: "Electronics",
    productIds: [11996, 21214, 5549],
    productsData: [],
  },
  {
    category: "Home / Office",
    productIds: [42327, 40471, 40470],
    productsData: [],
  },
];

const QuickLook = () => {
  const { title, name, content } = MetaTagContents.QUICKLOOK;
  const [loading, setLoading] = useState(true);
  const [productData, setProductData] = useState([]);
  const productIds = [
    4983, 19710, 4975, 11996, 21214, 5549, 42327, 40471, 40470,
  ];

  const getProducts = () => {
    let payload = {
      required_fields: ["id", "name", "thumbnail", "category", "platform"],
    };
    payload["order_by"] = "id desc";
    payload["filter_string"] = `(product_ids__in=[${productIds.join(",")}])`;

    axios.post(PRODUCT_LIST, payload).then((response) => {
      response.data.forEach((product) => {
        productsList.forEach((category) => {
          if (category.productIds.includes(product.id)) {
            category.productsData.push(product);
          }
        });
      });
      setProductData(productsList);
      setLoading(false);
    });
  };

  useEffect(() => {
    getProducts();
  }, []);

  const getThumbnail = (thumbnail, platform) => {
    let thumbnailUrl = thumbnail.replace(
      "https://d48f7equ64qjl.cloudfront.net/",
      ""
    );
    return getBaseURL(platform) + thumbnailUrl;
  };

  const ProductCard = ({ product }) => {
    return (
      <Card className="card-shadow quicklook-card" bordered={false}>
        <a
          href={`https://app.all3d.ai/product_public_viewer/product_3d_model/${product.id}`}
          className="suggested-product"
        >
          <div className="suggested-product apply-Avenir">
            <div
              className="store-card"
              style={{
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img
                key={product.id}
                className="product-image"
                src={getThumbnail(product.thumbnail, product.platform)}
                style={{ objectFit: "contain" }}
                alt="Product Image"
              />
            </div>
            <Tooltip
              title={<span className="manrope f-12 white">{product.name}</span>}
            >
              <div
                className="manrope f-18 w-500 black-00 clamp-text w-90"
                style={{ margin: "10px 20px 5px 20px", textAlign: "center" }}
              >
                {product.name}
              </div>
            </Tooltip>
          </div>
        </a>
      </Card>
    );
  };

  const WebVersion = () => {
    return (
      <div className="quicklook-container">
        <div className="quicklook-inner-container">
          <div className="quicklook-heading-content">
            <h1 className="font lora w-400 f-32 ws-6 black-2b">Quick Look</h1>
            <p>
              3D models created by the ALL3D.ai platform for immersive,
              real-world experiences. Contact us today to bring your products to
              life in brilliant 3D!
            </p>
          </div>
          <div className="quicklook-product-container">
            {
              loading && (
                <div className="quicklook-loading-container">
                  <LoadingOutlined />
                </div>
              )
            }
            {productData.map((product, index) => {
              return (
                <div key={index} className="quicklook-category-container">
                  {/* <hr className="quicklook-divider" /> */}
                  {/* <span className="font lora w-400 f-24 ws-6 black-2b">
                    {product.category}
                  </span> */}
                  <div className="quicklook-product-list">
                    {product.productsData.map((product, index) => {
                      return <ProductCard key={index} product={product} />;
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  const MobileVersion = () => {
    return <div></div>;
  };

  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <link rel="canonical" href="https://all3d.ai/quicklook" />
        <meta name={name} content={content} />
      </Helmet>

      <NavBar />

      <Layout>
        <WebVersion />
        <MobileVersion />
      </Layout>
      <Footer />
    </React.Fragment>
  );
};

export default QuickLook;
